


.foot{
    width: 100vw;
    height: 214px;
    background-color: #617b81;
    // background-color:  #8DA4A9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 222px 0 0 0 ;


    &__link{
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color:  #E9F3F5;
        position: relative;
        margin: 28px 0 0 0;

        &>svg{
            position: absolute;
            width: 34px;
            height: 34px;
            bottom: 0;
            left: 8px;
            z-index: 2;

        }
    }

    &__title{
        font-size: 16px;
        font-family: "berkshires";
        color:  #E9F3F5;
        line-height: 120%;
        text-transform: capitalize;
    }
    &__txt{
        font-weight: 300;
        color: #ffffff60;
    }
}


@media(min-width:1100px){
    .foot{
        margin: 380px 0 0 0 ;

        &--small{
            margin: 160px 0 0 0;
        }
    }
}