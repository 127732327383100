/// création de la box filtre du choix des chats / chattons 

.sect {

    &__boxSelcetCat {
        display: flex;
        align-items: center;
       
    }
}


.boxSelectCat {

    &__button {
        border: none;
        width: 73px;
        height: 32px;
        font-family: "roboto_slab";
        font-weight: 400;
        font-size: 16px;
        color: #000;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;

        &--actif{

            &>svg{
                transform: rotate(180deg);
                transition: 0.6s;
            }
        }

    }

    &__box {
        width: 100%;
        height: 192px;
        border-radius: 12px;
        background-color: #E0E3E460;
        margin: 8px 0;
        display: block;
        transition: 0.3s;

        &--dead{
            display: none;
            transition: 0.3s;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 6px 0 6px 18px;

        &:nth-child(1) {
            padding: 16px 0 0 0;
        }

        &>div {
            margin: 0 0 0 5vw;

            &>label {
                padding: 3px;
                margin: 0 8px;
                font-weight: 200;
            }

            &:nth-child(3) {
                margin: 0 0 0 1.5vw;
            }
        }

    }

    &__inputBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    &__buttonSelect {
        height: 30px;
        width: 183px;
        border-radius: 8px;
        background-color: #8DA4A9;
        font-family: "roboto_slab";
        font-weight: 300;
        font-size: 16px;
        color: #fff;
        border: none;
        margin: 10px  0  0;
        transform: translateX(50%);
    }
}