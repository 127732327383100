@font-face {
    font-family: "berkshires";
    src: url(../../assets/fonts/BerkshireSwash-Regular.ttf);
     font-display: swap;
}

@font-face {
    font-family: "roboto_slab";
    src: url(../../assets/fonts/RobotoSlab-VariableFont_wght.ttf);
     font-display: swap;
}


h1, h2, h3 {
    font-family: "berkshires";
    font-size: 38px;
    font-weight: 400;
    line-height: 100%;
    color: #0E1718;
    text-transform: capitalize;
}
p{
    font-family: "roboto_slab";
    font-size: 16px;
    font-weight: 300;
    line-height:160% ;
    color: #000000;
}