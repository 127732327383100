.sect {

    &__groupeCouple {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        height: 206px;
        width: 100%;

    }

    &__montagePhotoCat{
        width: 100%;
        height: 260px;
        display: grid;
        grid-template-columns: 183px 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: 
        "im1 im2 im3"
        "im1 im4 im4";
        margin: 21px 0 0 -28px;

    }
}

.montagePhotoCat{

    border-radius: 6px;
    object-fit: cover;
    filter: saturate(10%);
    margin: 3px;

    &:nth-child(1){
        height: 260px;
        width: 180px;
    }
    &:nth-child(2){
        height: 163px;
        width: 23.5vw;
    }
    &:nth-child(3){
        height: 163px;
        width: 23.5vw;
    }
    &:nth-child(4){
        height: 93px;
        width: 50vw;
    }
}


.groupeCouple {
    display: flex;
    position: relative;

    &__img {
        border-radius: 10px;
        filter: saturate(10%);
        height: 100%;
        max-height: 206px;
        width: 152px;
        object-fit: cover;
    }

    &__txt {
        font-weight: 600;
        font-size: 10px;
        //color: #ffffff60;
        color: #000000c9;
        line-height: 100%;
        margin: 4px 0 0 0;
    }

    &__title {
        font-weight: 400;
        font-size: 21px;
        font-family: "berkshires";
        color: #fafafa;
        line-height: 80%;
    }

    &__box {
        position: absolute;
       // top: 168px;
       bottom: 8px;
        left: 8px;
    }
    &__deco{
        position: absolute;
        font-family: "berkshires";
        line-height: 100%;
        font-size: 120px;
        color: rgba(141, 164, 169, 0.6);
        top: 62px;
        left: 33vw;
        z-index: 2;
        

    }
}


.im1{
    grid-area: im1;
}

.im2{
    grid-area: im2;
}

.im3{
    grid-area: im3;
}

.im4{
    grid-area: im4;
}


@media(min-width:1100px){
    .sect {

        &__groupeCouple {
            flex-direction: column;
            height: 300px;
            width: 35vw;
    
        }
    
        &__montagePhotoCat{
            width: 100%;
            height: 60vh;
            //height: 260px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin: 51px 0 0 -28px;
    
        }
    }

    .groupeCouple {
    
        transform:  translateY(40px);
        &:nth-child(2){
            transform: translateX(320px) translateY(-120px);
        }
        &__img {
            max-height: 380px;
            width: 274px;
        }
    
        &__txt {
            //color: #ffffff60;
            color: #000000c9;
            margin: 4px 0 0 0;
        }
    
        &__box {
            position: absolute;
           // top: 168px;
           bottom: 8px;
            left: 8px;
        }
        &__deco{
            font-size: 180px;
            top: 222px;
            left: 241px;
            
    
        }
    }
    .montagePhotoCat{

        border-radius: 6px;
        object-fit: cover;
        filter: saturate(10%);
        margin: 3px;
    
        &:nth-child(1){
            height: 60vh;
            width: 33vw;
        }
        &:nth-child(2){
            height: 30vh;
            width: 33vw;
        }
        &:nth-child(3){
            height: 30vh;
            width: 23.5vw;
        }
        &:nth-child(4){
            height: 30vh;
            width: 57vw;
        }
    }
    
}