
// home design 

.main{
    width: 100vw;
    min-height: 100vh;
    //height: 300vh;
    padding: 60px 28px 0 ;
    position: relative;
    overflow-x: hidden;
}


.sect{

    

    &__first{
        min-height: 100vh;
        //height: calc(100vh + 500px);
        height: 100vh;
        width: 100%;

        &--small{
            height: 60vh;
        }
        &--contact{
            min-height: auto;
            height: auto;
        }
    }
    &__second{
        position: relative;
    }
    &__third{
        margin: 122px 0 168px;
        position: relative;
    }
}

@media(min-width:1100px){

    .sect{

        &__first{

            &--chat{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        &__second{
            margin: 160px 0;
            max-height: 350px;
            height: 100vh;
            width: 40vw;
            max-width: 420px;
            margin: 0 0 0 50%;
           // background-color: blue;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 18px;


            &>a{
                width: 100%;
                transform: translateX(0);
                margin: -18px 0 0 0;
            }

            &--big{
                width: 100vw;
                margin: 160px auto 90px;
                padding: 0 3vw;
                max-width: 1440px;
                max-height: 90vh;

            }
            &--row{
                display: flex;
                flex-direction: row;
                column-gap: 28px;
                justify-content: space-around;
                align-items: flex-start;
                width: 100vw;
                height: 74vh;
                max-width: none;
                margin: 160px auto 90px;
                padding: 120px 3vw 0;
                max-height: none;
            }

        }
        &__third{
            margin: 122px 0 168px;
            position: relative;
            padding: 0 3vw;
            &--column{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &--contact{
                height: 800px;
            }
        }
        &__boxPc{
            padding: 51px 0;
        }
        &__pcDisplay{
            display: flex;
            flex-direction: row;
            column-gap: 28px;
            justify-content: space-around;
            margin: 122px 0;
        }

        &__four{
            padding: 0 3vw;
        }
    }
}