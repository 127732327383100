@import 'utils/variables';
@import 'components/type';
@import 'utils/fonts';

@import 'components/nav';
@import 'components/home';
@import 'components/images';
@import 'components/text';
@import 'components/decors';
@import 'components/slider';
@import 'components/groupeCouple';
@import 'components/cardCat';
@import 'components/filtre';
@import 'components/overlay';
@import 'components/footer';

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

@media(min-width:1100px){
    body{
        overflow-x: hidden;
    }
}



