///création de la nav
/// 
.head {
    width: 100vw;
    height: 60px;
    display: flex;
    flex-direction: column;
    // padding: 10px 21px 10px 28px;
    background-color: #E0E3E420;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    &--actif{
        height: 100vh;
        background-color: #e0e3e4;
    }
    &--none{
        display: none;
    }
    &__logo{
        width: 34px;
        height: 31px;
    }

    &__sect {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 10px 21px 10px 28px;
       

    }
    &__title{
        font-family: "berkshires";
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
    }


    &__btn {
        width: 28px;
        height: 21px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        cursor: pointer;

        &>span {
            width: 100%;
            height: 3px;
            border-radius: 2px;
            background-color: #000;
            transition: 0.2s;
            &:nth-child(2) {
                // display: flex;
                // flex-direction: row;
                // justify-content: space-between;
                width: 22px;
                margin: 0 0 0 3px;

            }
        }

        &--actif{
            &>span{
                transition: 0.2s;
                &:nth-child(1){
                    transform: rotate(45deg) translateX(6px) translateY(6px);
                }
                &:nth-child(2){
                    transform: scale(0);
                }
                &:nth-child(3){
                    transform: rotate(-45deg) translateX(6px) translateY(-6px);;
                }
            }
        }
    }

    &__nav {
        animation: openNav ease 0.6s;
        //animation-delay: 0.8s;
        height: 100%;
        margin: 51px 0 0 51px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //transform: translateX(0);
        //transition: 0.6s;
       

        &--none{
            display: none;
            transform: translateX(100vw);
            transition: 0.6s;
        }
    }

    &__el{
       
        font-family: "berkshires";
        font-size: 28px;
        font-weight: 400;
        text-transform: capitalize;
        margin: 16px 0;

        &>a{
            color: #00000073;

            &:hover, &:checked{
                color: #000000;
            }

            &>svg{
                opacity: 0;
            }
        }
        
        &--actif{
            &>a{
                color: #000000;
                &>svg{
                    opacity: 1;
                    margin: 0 0 0 8px;
                }
            }
           
        }
       
    }

    &__boxFoot {
        width: calc(100% + 51px);
        height: 106px;
        //background-color: #8DA4A9;
        background-color: #617b81;
        padding: 30px 0;
        margin: 0 0 0 -51px;

        &>p {
            text-align: center;
            color: #ffffff;
            font-family: "berkshires";
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;

            &:nth-child(2) {
                color: #ffffff80;
                font-family: "roboto_slab";
                font-size: 16px;
                font-weight: 300;
            }

        }
    }
}


@keyframes openNav {

    0%{
        transform: translateX(100vw);
        opacity: 0;
    }
    100%{
        transform: translateX(0vw);
        opacity: 1;
    }
}