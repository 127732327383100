.slider {

    margin: 0 12px 0 0;
    position: relative;
    &:nth-child(1){
        margin: 0 12px 0 28px;
    }
    &__list{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: scroll;
    }
   

    &__img {
        width: 358px;
        height: 206px;
        border-radius: 10px;
        filter: saturate(10%);
    }
    &__txt{
        position: absolute;
        bottom: 8px;
        left: 10px;
        font-size: 14px;
        
    }
}


.sect__sliderImg{
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    height: 206px;
    width: 100vw;
    margin: 38px 0 0 -28px;
    //overflow: hidden;
}


@media(min-width:1100px){

    .sect{

        &__sliderImg{
            width: 40%;
            min-width: 450px;
            
            height: 100%;
        }

        
    }

    .slider {

        margin: 0 12px 0 0;
        position: relative;
        &:nth-child(1){
           margin: 0 12px 0 0px;
        }
        &__list{
            flex-direction: column;
            align-items: center;
        }
       
    
        &__img {
            //width: 358px;
            width: 35vw;
            height: 206px;
            object-fit: cover;
        }
        &__txt{
            position: absolute;
            bottom: 8px;
            left: 10px;
            font-size: 14px;
            
        }
    }
}