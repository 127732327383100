.cardCat {

    &__img {
        width: 180px;
        max-width: 180px;
        height: 271px;
        max-height: 271px;
        border-radius: 6px;
        object-fit: cover;
        filter: saturate(10%);
    }
    &__box{
        display: flex;
        flex-direction: column;
        width: 180px;
        height: 271px;
        color: red;
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        &:hover{
            &>img{
                transition: 0.8s;
                filter: saturate(40%);
                transform: scale(1.2);
                border-radius: 12px;
            }
        }
    }
    &__overlayBox{
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: 55;
        left: 0;     
        border-radius: 20px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;

        &--none{
            display: none;
        }
        

    }
}

.sect{
    &__cardCat{
        width: calc(100vw - 16px);
        margin: 21px 8px 0 -20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 28px;

    }

    &__boxSelcetCat{
        width: 100%;
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 28px 0 0 0 ;
    }
}


.boxSelectCat{


    &__btn{
        border: none;
        margin: 0 16px 0 0 ;
        cursor: pointer;
        transition: 0.3s ease-out;
        &:nth-child(2){
            opacity: 50%;
            margin: 0;
        }

        &:hover{
            transition: 0.3s ease-in;
            transform: scale(0.6);
        }
    }
}


@media(min-width:1100px){


    .sect{

        &__cardCat{
            width: calc(100% - 12vw);
            justify-content: space-evenly;
    
        }
        &__boxSelcetCat{
            width: 120px;
            margin: 28px 0 0 60%;
            &--big{
                width: 80%;
                margin: 28px 0 0 0;
                display: flex;
                flex-direction: row;
            }
        }

    }


    .cardCat {

        &__img {
            width: 100%;
            max-width: none;
            height: 100%;
            max-height: none;

        }
        &__box{
            display: flex;
            flex-direction: column;
            width: 30vw;
            max-width: 480px;
            height: 380px;
            color: red;
            position: relative;
            overflow: hidden;
            border-radius: 10px;
    
            &:hover{
                &>img{
                    transition: 0.8s;
                    filter: saturate(40%);
                    transform: scale(1.2);
                    border-radius: 12px;
                }
            }
        }
        &__overlayBox{
            width: 100vw;
            height: 100vh;
            position: absolute;
            z-index: 55;
            left: 0;     
            border-radius: 20px;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
    
            &--none{
                display: none;
            }
            
    
        }
    }
    
}