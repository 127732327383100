.sect {

    &__img {
        &--big {
            border-radius: 12px;
            min-width: 314px;
            width: 83.8vw;
            min-height: 475px;
            height: 71.4vh;
            filter: saturate(10%);
            object-fit: cover;
        }

        &--small {
            width: 189px;
            height: 281px;
            border-radius: 6px;
            margin: -277px 0 0 180px;
            //position: relative;
            position: sticky;
            top: 120px;
            left: 60px;
            transition: 3s;
            z-index: 2;
            border: 6px solid #F7F8F999;
        }

        &--carré {
            height: 256px;
            width: 265px;
            border-radius: 12px;
            filter: saturate(10%);
            object-fit: cover;
            //transform: translate(50%, 50%);
        }

        &--center {
            margin: 28px 0% 28px 15%;
        }
    }


    &__svgBg {
        position: absolute;
        top: 0;
        right: -28px;
        opacity: 0.8;
        z-index: -2;
    }
}


@media(min-width:1100px) {
    .sect {

        &__img {


            &--big {
                width: 100%;
                height: 85.4vh;
            }

            &--small {
                width: 300px;
                height: 381px;
                margin: -277px 0 0 180px;
                //position: relative;
                position: sticky;
                top: 40px;
                right: 60px;
                border: 6px solid #f7f8f933;
            }
            &--carré {
                height: 620px;
                width: 830px;
                margin: 0 auto 38px;

            }
            // &--center {
            //     margin: 28px 0% 28px auto;
            //     transform: translateX(50%);
            // }

        }

        &__svgBg {
            position: absolute;
            top: 0;
            right: -28px;
            opacity: 0.8;
            z-index: -2;
            width: 60vw;
            max-height: 180vh;

            &--contact{
                height: 80vh;
                max-height: 80vh;
            }
        }
    }
}