.sect {

    &__boxDecor {
        padding: 91px 0 38px 0;
        position: relative;

        &--small {
            padding: 38px 0 38px 0;

            &>svg {
                &:nth-child(1) {
                    top: 12px;

                }

                &:nth-child(2) {
                    top: 12px;
                }

                &:nth-child(3) {
                    top: 68px;
                }
            }
        }

    }

    &__patteMiniDecor {
        position: absolute;

        &:nth-child(1) {
            left: 0;
            top: 71px;

        }

        &:nth-child(2) {
            left: 50px;
            top: 71px;
            transform: rotate(175deg);
        }

        &:nth-child(3) {
            left: 0;
            top: 126px;
            transform: rotate(175deg);
        }
    }

    &__decorSpan {
        position: absolute;
        left: 50%;
        font-size: 68px;
        color: #00000030;
        z-index: 2;
    }
}

@media(min-width:1100px) {

    .sect {
        &__boxDecor {
            padding: 122px 0 51px 0;
            position: relative;
            //width: 400px;

            &>h2{
                text-align: left;
                padding: 0 0 0 51px;
            }

            &--small {
                padding: 38px 0 38px 0;
                max-width: 60ch;
                width: 90%;
                &>h1{
                    padding: 0;
                }
    
                &>svg {
                    &:nth-child(1) {
                        top: 12px;
                        left: 10%;
    
                    }
    
                    &:nth-child(2) {
                        top: 12px;
                        left: 0%;
                    }
    
                    &:nth-child(3) {
                        top: 68px;
                        left: 0%;
                    }
                }
            }
            &--introPapier{

                padding: 38px 0 38px 0;
                margin: 0 ;
                max-width: 94ch;
                width: 90%;
                &>h1{
                    padding: 0;
                }
    
                &>svg {
                    &:nth-child(1) {
                        top: 12px;
                        left: 10%;
    
                    }
    
                    &:nth-child(2) {
                        top: 12px;
                        left: 0%;
                    }
    
                    &:nth-child(3) {
                        top: 68px;
                        left: 0%;
                    }
                }
            }

        }

        &__patteMiniDecor {
            position: absolute;

            &:nth-child(1) {
                left: 0;
                top: 98px;

            }

            &:nth-child(2) {
                left: 50px;
                top: 98px;
                transform: rotate(175deg);
            }

            &:nth-child(3) {
                left: 0;
                top: 150px;
                transform: rotate(175deg);
            }
        }

        &__decorSpan {
            left: 263px;
        }
    }
}