.sect {


    &__title {
        font-size: 28px;
        text-align: right;
        text-transform: capitalize;
        line-height: 140%;

        &--intro {
            line-height: 47px;
        }

        &--big {
            font-size: 38px;
            line-height: 90%;
            position: relative;
        }


    }

    &__txt {

        max-width: 60ch;

        &--margin {
            margin: 21px 0 38px;
        }

        &--color {
            font-weight: 600;
            color: #617B81;
        }


    }

    &__linkB {
        color: #617b81;
        transition: 0.3s;

        &:hover {
            transition: 0.3s;
            color: #263133;
        }

    }

    &__link {
        font-family: "roboto_slab";
        font-weight: 800;
        font-size: 16px;
        line-height: 140%;
        display: inline-block;
        width: 248px;
        height: 43px;
        background-color: #E9F3F5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        transform: translateX(25%);
        transition: 0.3s ease-in;
        position: relative;

        &>span:nth-child(1) {
            position: absolute;
            top: 20px;
            left: 0;
        }

        &>span:nth-child(2) {
            position: absolute;
            top: -6px;
            right: 0;
            transform: rotate(175deg);
        }

        &:hover {
            background-color: #617B81;
            transition: 0.3s ease-out;
            color: #fff;
            font-weight: 300;

            &>span>svg>g {
                &>path {
                    fill: #E9F3F5;
                }

                &>ellipse {
                    fill: #fff;
                }
            }
        }

        &--clair {
            background-color: #F9F9F9;
            color: #000;
        }
    }


    &__linkTxt {
        font-weight: 500;
        color: #617B81;
        text-decoration: underline;


    }
}


@media(min-width:1100px) {
    .sect {
        &__title {
            font-size: 38px;

            &--big {
                font-size: 51px;
            }
        }

        &__txt {
            font-size: 18px;
        }

        &__link {
            width: 90%;
            transform: translateX(5%);
        }
    }
}